<script>
import Message from "../../messages";
export default {
  props: {
    user: Object
  },
  components: {

  },
  data() {
    return {
      canUpdate: false,
      inActiveMessage: {},
    };
  },
  mounted() {
    // this.user.status
    // 'RP'
    ///console.log("Inactive status:", this.user);
  
    this.inActiveMessage = Message.inactiveStatus[this.user.status];
    this.canUpdate = this.inActiveMessage.canUpdate;
  },
  methods: {
    goToUpdate() {
      this.$router.push({ path: "/update" });
    }
  }
};
</script>
<template>
  <b-container class="page dashboard-inactive-status">
    <div class="content-wrap">
      <h1>Welcome {{ user.first_name + " " + user.last_name }}</h1>

      <b-alert show variant="danger" v-if="user.status=='E'">
        <p>{{$t("inactive_status.E")}}</p>
      </b-alert>

      <b-alert show variant="danger" v-if="user.status=='R' || user.status=='RP'">
        <p>{{user.reason}}</p>
      </b-alert>

      <div v-if="user.status == 'I'">
         <p>{{$t("inactive_status.I")}}</p>
      </div>

      <div v-if="user.status == 'D'">
         <h5 class="warning">{{ user.reason }}</h5>
      </div>

      <p class="text-right">
        <b-button
          variant="primary"
          @click="goToUpdate"
          v-if="inActiveMessage.canUpdate"
          >Update Profile</b-button
        >
      </p>
    </div>
  </b-container>
</template>
<style scoped>
  .warning {
    text-align: center;
    color: #FF3333;
    padding-top: 2rem;
  }
</style>