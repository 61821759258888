export default {
    inactiveStatus:{
        I:{
            name: 'In Review',
            canUpdate: false,
        },
        R:{
            name: 'Rejected',
            canUpdate: false,
            style: 'disabled'
        },
        RP:{
            name: 'Rejected: Pending Additional Information',
            canUpdate: true,
            style: 'disabled'
        },
        D:{
            name: 'Disable',
            canUpdate: false,
            style: 'disabled'
        },
        E:{
            name: 'Expire: Pending Updated Information',
            canUpdate: true,
            style: 'disabled'
        },
        
    }
    
}